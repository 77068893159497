<template>
  <div>
    <component :is="FastLoading" />
    <div v-show="!$store.state.fastCarregando" class="container fast-error-container">
      <div class="row">
        <div class="col-md-12">
          <div class="error-template">
            <img v-lazy="imageSiteHeader" class="fast-logo-error" />

            <h2>Erro de autenticação</h2>
            <div class="error-details">
              Desculpe, um erro ocorreu, a página requisitada não foi encontrada!
            </div>
            <div class="error-actions">
              <a :href="whatsLink" target="_blank" class="btn btn-default btn-lg"> <span class="glyphicon glyphicon-envelope" /> Contatar Suporte </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";

export default {
  name: "SSOAuth",
  mixins: [methods],
  data: function() {
    return {
      imageSiteHeader: settings.fastLogoPrincipal,
      paginaAtual: this.$route.name,
      fastTemplate: settings.fastTemplate,
      whatsLink: "https://api.whatsapp.com/send?phone=" + settings.fastTelefoneContato + "&text=",
      erroSSO: false,
    };
  },
  computed: {
    FastLoading() {
      return () => import(`@/templates/${this.fastTemplate}/components/Loading.vue`);
    },
  },
  mounted() {
    if (this.getUrlParameter("sso_token") && this.getUrlParameter("idPlataforma")) {
      console.log("Parâmetros identificados...");
      this.setFastSessao(settings.fastSessaoToken, { access_token: this.getUrlParameter("sso_token")})
      //sessionStorage.setItem(settings.fastSessaoToken, JSON.stringify({ access_token: this.getUrlParameter("sso_token") }));
      console.log("Gravando na sessão...", sessionStorage.getItem(settings.fastSessaoToken));
      this.promiseGetUsuario()
        .then((res) => {
          console.log(res)
          if (!res.error) {
            this.setFastSessao(settings.fastSessaoUsuario, res)
            if (this.getUrlParameter("url_redirect")) {
              window.location.href = this.getUrlParameter("url_redirect");
            } else {
              window.location.href = "/Plataforma/" + this.getUrlParameter("idPlataforma") + "/aluno";
            }
          } else {
            this.erroSSO = true;
            this.$store.state.fastCarregando = false;
          }         
        })
        .catch(() => {
          this.erroSSO = true;
          this.$store.state.fastCarregando = false;
        });
    } else {
      this.erroSSO = true;
      this.$store.state.fastCarregando = false;
    }
  },
  methods: {
    promiseGetUsuario() {
      return new Promise(async (resolve, reject) => {
        this.promiseGetFastApi(
          "api/fast_plataforma_usuario/busca_usuario",
          ""
        )
          .then((obj) => {
            resolve(JSON.parse(obj));
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
  },
};
</script>

<style scoped>
body {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABZ0RVh0Q3JlYXRpb24gVGltZQAxMC8yOS8xMiKqq3kAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzVxteM2AAABHklEQVRIib2Vyw6EIAxFW5idr///Qx9sfG3pLEyJ3tAwi5EmBqRo7vHawiEEERHS6x7MTMxMVv6+z3tPMUYSkfTM/R0fEaG2bbMv+Gc4nZzn+dN4HAcREa3r+hi3bcuu68jLskhVIlW073tWaYlQ9+F9IpqmSfq+fwskhdO/AwmUTJXrOuaRQNeRkOd5lq7rXmS5InmERKoER/QMvUAPlZDHcZRhGN4CSeGY+aHMqgcks5RrHv/eeh455x5KrMq2yHQdibDO6ncG/KZWL7M8xDyS1/MIO0NJqdULLS81X6/X6aR0nqBSJcPeZnlZrzN477NKURn2Nus8sjzmEII0TfMiyxUuxphVWjpJkbx0btUnshRihVv70Bv8ItXq6Asoi/ZiCbU6YgAAAABJRU5ErkJggg==);
}
.fast-error-container {
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fast-error-container .fast-logo-error {
  display: block;
  margin: 20px auto;
  max-width: 50%;
}
.fast-error-container .error-template {
  padding: 40px 15px;
  text-align: center;
}
.fast-error-container .error-actions {
  margin-top: 15px;
  margin-bottom: 15px;
}
.fast-error-container .error-actions .btn {
  margin-right: 10px;
}
</style>
